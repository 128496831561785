









































































import { Component, PropSync, Ref, Watch, Prop } from 'vue-property-decorator';
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import Validation from "@/mixins/validation";
import CountryAutocomplete from "@/components/common/country/CountryAutocomplete.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { AgencyUpsertModel } from "@/api/generated";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { CountryAutocomplete } })
export default class AgencyCreatetDialog extends Validation {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: AgencyUpsertModel = {
    name: "",
    countryId: "",
    licenceNumber: "",
    postalAddress: "",
    companyRegistrationNumber: "",
    discriminator: getContractType(this.discriminator)
  };

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private async onAgencyCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.AgencyService.apiAgencyPost(this.model);
      snackbarModule.setSnackbarMessage("Agency created");
      this.$emit("agency-created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create agency");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
