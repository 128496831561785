


















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AgencyTable from "@/components/agency/AgencyTable.vue";
import AgencyCreateDialog from "@/components/dialogs/AgencyCreateDialog.vue";
import SnackbarModule from "@/store/snackbarModule";
import Api from "@/api";
import { AgencyModel } from "@/api/generated";
import Discriminator from '../../types/Discriminator';

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: { AgencyCreateDialog, AgencyTable }
})
export default class AgencyOverview extends Vue {
  private tableRefreshKey = false;
  private createDialog = false;

  public get discriminator() {
    return Discriminator.LAND_WORKERS;
  }

  private onCreateAgency() {
    this.createDialog = true;
  }

  private async onDeleteAgency(agency: AgencyModel) {
    if (!agency.agencyId) {
      return;
    }

    try {
      await Api.AgencyService.apiAgencyAgencyIdDelete(agency.agencyId);
      snackbarModule.setSnackbarMessage(`Agency successfully deleted`);
    } catch {
      snackbarModule.setSnackbarMessage(`Failed to delete agency`);
    } finally {
      this.tableRefreshKey = !this.tableRefreshKey;
    }
  }
}
