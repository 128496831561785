































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { DataTableHeader } from "vuetify";
import nameof from "@/utility/nameof";
import { AgencyModel } from "@/api/generated";
import Api from "@/api";
import SnackbarModule from "@/store/snackbarModule";
import UserModule from "@/store/userModule";
import Discriminator from '@/types/Discriminator';
import getContractType from '../../utility/getContractType';

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class AgencyTable extends Vue {
  @Prop({ default: Discriminator.VESSELS }) discriminator!: Discriminator;

  private loading = false;
  private items: Array<AgencyModel> = [];
  private headers: Array<DataTableHeader<AgencyModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Name", value: nameof<AgencyModel>("name") },
    { text: "Country", value: nameof<AgencyModel>("countryName") },
    { text: "Licence Number", value: nameof<AgencyModel>("licenceNumber") },
    {
      text: "Registration Number",
      value: nameof<AgencyModel>("companyRegistrationNumber")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getAgencies();
  }

  private async getAgencies() {
    try {
      this.loading = true;
      if (userModule.role == "AGM") {
        const response = await Api.AgencyService.apiAgencyAgencyadminUserIdGet(
          userModule.userId
        );
        this.items = response.data;
      } else {
        const response = await Api.AgencyService.apiAgencyDiscriminatorGet(getContractType(this.discriminator));
        this.items = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to retrieve agencies");
    } finally {
      this.loading = false;
    }
  }

  private onUpdateAgency(agency: AgencyModel) {
    if (!agency.agencyId) {
      return;
    }

    this.$router.push({
      name: "Agency Edit",
      params: { agencyId: agency.agencyId }
    });
  }
}
